import {NarrativeChapter} from 'src/component/player/narrative/narrativeChapter';
import {IHttpClient} from 'src/core/http';

export interface IPlayerService {
	getPlayerProfile(playerId: string): Promise<any>;
	sendNotification(playerId: string, notificationContext: any): Promise<any>;
	addSegment(playerId: string, segmentKey: string, segmentValue: any): Promise<any>;
	updateNarrativeChapter(playerId: string, context: string, narrativeChapter: NarrativeChapter): Promise<any>;
	removeSegment(playerId: string, segmentKey: string): Promise<any>;
	resetPlayer(playerId: string, resetType: string): Promise<any>;
	exportPlayer(playerId: string, fileName: string): Promise<any>;
	importPlayer(playerId: string, data: any): Promise<any>;
	simulateLogin(playerId: string, ip: string, platform: string, clientVersion: string, language: string): Promise<any>;
    getTelemetryEvents(playerId: string, eventId: string, startAt: string, endAt: string): Promise<any>;
    deleteMyRealForestData(playerId: string);
}

export class PlayerService implements IPlayerService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async updateNarrativeChapter(playerId: string, context: string, narrativeChapter: NarrativeChapter): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/player/${playerId}/narrative/${context}`, {
            chapter: narrativeChapter
        });
        return result.data;
    }

    public async getPlayerProfile(playerId: string): Promise<any> {
        const result = await this.httpClient.get(`${this.apiUrl}/player/profile/${playerId}`);
        return result.data;
    }

    public async sendNotification(playerId: string, notificationContext: any): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/player/${playerId}/notification`, notificationContext);
        return result.data;
    }

    public async addSegment(playerId: string, segmentKey: string, segmentValue: any): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/player/${playerId}/segment/${segmentKey}`, {segmentValue});
        return result.data;
    }

    public async removeSegment(playerId: string, segmentKey: string): Promise<any> {
        const result = await this.httpClient.delete(`${this.apiUrl}/player/${playerId}/segment/${segmentKey}`);
        return result.data;

    }

    public async resetPlayer(playerId: string, resetType: string): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/player/${playerId}/reset/${resetType}`, {});
        return result.data;
    }

    public async exportPlayer(playerId: string, fileName: string): Promise<any> {
        await this.httpClient.download(`${this.apiUrl}/player/${playerId}/export`, fileName);
    }

    public async importPlayer(playerId: string, data: any): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/player/${playerId}/import`, data);
        return result.data;
    }

    public async simulateLogin(playerId: string, ip: string, platform: string, clientVersion: string, language: string): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/player/${playerId}/simulateLogin`, {ip, platform, clientVersion, language});

        return result.data;
    }

    public async getTelemetryEvents(playerId: string, eventId: string, startAt: string, endAt: string): Promise<any> {
        return (await this.httpClient.get(`${this.apiUrl}/player/${playerId}/telemetry/event/${eventId}/start/${startAt}/end/${endAt}`)).data;
    }

    public async deleteMyRealForestData(playerId: string): Promise<any> {
        return (await this.httpClient.delete(`${this.apiUrl}/player/${playerId}/myrealforest`)).data;
    }
}
